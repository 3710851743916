import axios from 'axios';
import LogRocket from 'logrocket';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { useMemo, useState, useEffect, useCallback } from 'react';
import {
  AppState,
  useAuth0,
  Auth0Provider,
  LogoutOptions,
  PopupLoginOptions,
} from '@auth0/auth0-react';

import { AUTH0_API } from 'src/config-global';

import { AuthContext } from './auth-context';
import { useRouter } from '../../../routes/hooks';
import { Provider } from '../../../types/provider';


// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

function AuthProviderWrapper({ children }: Props) {
  const { isAuthenticated, user, isLoading, loginWithRedirect, loginWithPopup, logout, getAccessTokenSilently } = useAuth0();
  const [popupClick, setPopupClick] = useState(true);
  const [userRoles, setUserRoles] = useState<string[]>();
  const [currentUser, setCurrentUser] = useState<Provider>();
  const [providerList, setProviderList] = useState<String[]>([]);
  const router = useRouter();

  // LOGIN
  const handleLoginWithPopup = useCallback(
    async (options?: PopupLoginOptions) => {
      loginWithPopup?.(options);
      setPopupClick(false);
    },
    [loginWithPopup]
  );

  // LOGOUT
  const handleLogout = useCallback(
    async (options?: LogoutOptions) => {
      logout?.(options);
    },
    [logout]
  );

  const fetchProviders = async () => {
    try {
      const response = await axios.get(`${import.meta.env.VITE_API_URL}/providers/admin/emails`);
      setProviderList(response.data);
    } catch (error) {
      console.error('There was an error retrieving the provider data', error);
    }
  };

  useEffect(() => {
    const getAuthInfo = async () => {
      try {
        // get bearer token, otherwise auth check will fail with status 401
        const token: string = await getAccessTokenSilently();

        // TODO ask new CTO if this is a bad idea to display this here
        console.log("Token pulled: ", token);
        if (!token || typeof token !== 'string' || token.trim() === "") {
          console.log("Token is missing or invalid, logging out");
          await logout();
          return;
        }

        const decoded: JwtPayload = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        if (decoded.exp && decoded.exp < currentTime) {
          console.log("Token is expired, logging out");
          await logout();
          return;
        }
        console.log('Token not expired.')
        console.log(`Current user: ${user?.email}`)
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        //  check user's authorization. Throws an error if not authorized
        const currentProviderResponse = await axios.get(`${import.meta.env.VITE_API_URL}/auth`, {
          validateStatus: (status) => status === 200
        });
        console.log("Auth succeeded")
        const currentProvider: Provider = currentProviderResponse.data;
        setUserRoles(currentProvider.roles);
        setCurrentUser(currentProvider);


        // TODO: ts-ignore because email isn't a property of JwtPayload, but it is on the object in reality....
        // @ts-ignore
        LogRocket.identify(user?.email, {
          permissions: currentProvider.roles.toString(),
        })
        // Intercom({
        //   app_id: 'qkdvjz3s',
        //   user_id: currentProvider.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
        //   name: `${currentProvider.firstName  } ${  currentProvider.lastName}`, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        //   email: currentProvider.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        //   created_at: 1704067200, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
        // });

        // this was being called by every provider and causing delays in loading their left navbar
        if (providerList.length === 0 && currentProvider.roles.includes('super_admin')) {
          fetchProviders();
        }

      } catch(error) {
        if(error && error.response && error.response.status === 403) {
          console.log("User is not authorized, logging out");
          router.replace("/403");
        } else {
          console.log("Error occurred in getAuthInfo", error);
          // router.replace("/auth/external-login");
          // await logout({ logoutParams: { returnTo: `${import.meta.env.VITE_API_URL}/auth/external-login` } });
          await logout();
        }
      }
    };

    console.log('attempting to check authentication')
    if (!isLoading) {
      if (isAuthenticated) {
        console.log("User is authenticated, fetching token.");
        getAuthInfo();
      } else {
        console.log("User is not authenticated.");
        router.replace("/auth/external-login");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = isAuthenticated ? 'authenticated' : 'unauthenticated';

  const status = popupClick && isLoading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: {
        ...user,
        displayName: user?.name,
        photoURL: user?.picture,
        roles: userRoles,
      },
      currentUser,
      providerList,
      method: 'auth0',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      loginWithRedirect,
      loginWithPopup: handleLoginWithPopup,
      logout: handleLogout,
    }),
    [handleLoginWithPopup, handleLogout, loginWithRedirect, currentUser, providerList, status, user, userRoles]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

// ----------------------------------------------------------------------

export const AuthProvider = ({ children }: Props) => {
  const domain = AUTH0_API.domain ?? '';

  const clientId = AUTH0_API.clientId ?? '';

  const redirectUri = AUTH0_API.callbackUrl ?? '';

  const audienceName = AUTH0_API.audience ?? '';

  const onRedirectCallback = useCallback((appState?: AppState) => {
    console.log(appState);
    window.location.replace(appState?.returnTo || window.location.pathname);
  }, []);

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audienceName,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      <AuthProviderWrapper>{children}</AuthProviderWrapper>
    </Auth0Provider>
  );
};
